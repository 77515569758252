.antd-tab-right {
    .ant-tabs-nav {
        justify-content: end;

        .ant-tabs-nav-wrap {
            display: contents !important;
        }
    }
}

.antd-tab-text {
    .ant-tabs-tab-btn {
        font-size: 1rem !important;
        font-weight: bold;
    }
}

.antd-card-body-p-0 {
    .ant-card-body {
        padding: 0;
    }
}

.antd-collapse-bg-cloud {
    .ant-collapse-header {
        background: $cloud;
    }

    .ant-collapse-content-box {
        background: $cloud;
    }
}

.antd-order-detail-collapse {

    &.bg-white
    {
        background: #fff;
        .ant-collapse-item-active{
            background: #fff;
        }
    }

    .ant-collapse-item-active {
        border: 1px solid #F4F4F4;
        background: linear-gradient(to bottom, $cloud, #fff);
        border-radius: 1rem !important;
    }

    .ant-collapse-header,
    .ant-collapse-content-box,
    .ant-collapse-content {
        background: none !important;
    }

    .ant-collapse-content-box {
        border-bottom: 1px solid $slate-blue-02;
        border-radius: 1rem !important;
        padding: 1.5rem !important;

    }

    .ant-collapse-header {
        border-radius: 1rem !important;
        padding: 1.5rem !important;

    }
}

.menu-wrapper a {
    margin-left: 0 !important;
    width: 100% !important;
}

.oneamz-layout-menu {
    max-height: 100vh;
    overflow-y: auto;
}

.oneamz-layout-menu::-webkit-scrollbar {
    width: 0px;
    /* Scrollbar genişliği */
}

.oneamz-layout-menu::-webkit-scrollbar-track {
    background: transparent;
    /* Scrollbar track arka planı şeffaf */
}

.oneamz-layout-menu::-webkit-scrollbar-thumb {
    background: $slate-blue-02;
    /* Scrollbar thumb rengi */
    border-radius: 1rem;
    /* Scrollbar thumb yuvarlak köşeleri */
}

.oneamz-layout-menu::-webkit-scrollbar-thumb:hover {
    background: $slate-blue-05;
    /* Scrollbar thumb üzerine gelindiğinde rengi */
}

.antd-modal-secondary {
    .ant-modal-content {
        background: $cloud;
        border-radius: $outer-radius;
    }
    .ant-modal-title{
        background: $cloud;
    }
}

// .ant-avatar.ant-avatar-circle{
//     color: rgb(51, 62, 72) !important;
//     background-color: rgb(51, 62, 72, 0.3) !important;
// }


.btn.bg-slate-blue:hover {
    background-color: $slate-blue !important;
}


.ant-tabs .ant-tabs-tab+.ant-tabs-tab{
    margin: 0px !important;
}


.fba-label-upload .ant-upload-list{
    display: none !important;
}


.fnsku-label-upload .ant-upload-list{
    display: none  !important;
}

.storage-progress{
    .ant-progress-bg{
        background-color: $oneamz-yellow;
    }

    .ant-progress-success-bg{
        background-color: $slate-blue;
    }
}

.product-storage-progress{
    .ant-progress-bg{
        background-color: $cloud-05;
    }

    .ant-progress-success-bg{
        background-color: $oneamz-yellow;
    }
}

@media (max-width: 768px) {
    .h-4 {
        font-size: 16px;
    }
    .h-3 {
        font-size: 20px;
    }
    .ant-tabs-tab{
        min-width: 60px!important;
    }
}
