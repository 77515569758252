.amz-radio{

    span {
        border: none !important;
    }

    span .ant-radio-inner{
        background: pure-white;
        border: 1px solid #BCBCBC;
        height: 20px;
        width: 20px;
    }

    span.ant-radio-checked {

        .ant-radio-inner{
            background: $slate-blue !important;

        }
    }
}

.amz-radio-oneamz-yellow{

    span {
        border: none !important;
    }

    span .ant-radio-inner{
        background: #70787F;
        border: 1px solid #BCBCBC;
        height: 20px;
        width: 20px;
    }

    span.ant-radio-checked {

        .ant-radio-inner{
            background: $oneamz-yellow !important;

        }
    }
}
