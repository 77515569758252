.App {
  text-align: center;
  background-color: #F4F4F4;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: pure-white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-layout-content {
  margin: 0 0 1em 200px
}


@media screen and (min-width: 1325px) {
  .my-shipments-mobile {
    display: none;
  }

}

@media screen and (max-width: 1324px) {
  .my-shipments-pc {
    display: none;
  }

}

@media screen and (max-width: 1200px) {
  .wallet-current-balance-button{
    margin: 2em 0 0 0;
  }
  .wallet-earnings-select{
    margin: 2em 0 0 0;
  }
  .wallet-expenses-select{
    margin: 2em 0 0 0;
  }

}

@media screen and (max-width: 990px) {
  .ant-layout-content {
    margin: 0 0 0 0 !important;
    padding: 0.5em !important;
  }

}


@media screen and (max-width: 600px) {

  
}
