.menu-wrapper {
    a {
        width: 200px;
        display: flex;
        align-items: center;
        justify-content: start;
        margin-bottom: 10px;
        margin-left: 20px;

        .menu-icon {
            background: #F7F7F7;
            padding: 10px;
            border-radius: 8px;

            &:hover {
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            }

            .anticon.anticon-setting{
                color: black;
            }
        }

        &.active {
            .menu-icon {
                background: #222323;

                .anticon.anticon-setting{
                    color: #F7F7F7;
                }

                path,
                rect {
                    stroke: #F7F7F7 !important;
                }
            }
        }
    }
}

.p-5{
    padding: 5px;
}

.login-layout{
    width: 100vw;
    height: 100vh;
    background-image: url('../assets/loginV2/bg-login.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
