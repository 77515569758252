.dashboard-v2 {
    background: pure-white;
    .starter-card {
        background: rgba(51, 62, 72, 1);
        color: pure-white;
        width: 100%;
        border-radius: 16px;

        position: relative; // Pozisyonlamayı kontrol etmek için
        overflow: hidden; // Resim Paper sınırlarını aştığında gizlemek için

        &::after {
            // Pseudo-element ile arka plan resmi ekleme
            content: ""; // Pseudo-element için içerik
            position: absolute; // Pozisyonu absolute yaparak konumlandırma
            bottom: -6rem; // Alt kısma yasla
            right: -8rem; // Sağ kısma yasla
            background-image: url("../../assets/dashboard-v2/dahsboard-starter.png"); // Resim yolu
            background-size: contain; // Resmin boyutunu sığdır
            background-repeat: no-repeat; // Tekrar etmemesi için
            width: 700px;
            height: 300px;
            z-index: 0;

            @media (max-width:1198.8px) {
                width: 300px;
                height: 100px;
            }
            
        }

        p {
            font-family: Amazon, sans-serif;
            font-size: 12px;
            font-weight: 700;
            line-height: 14px;
            letter-spacing: 0.03em;
            text-align: left;
            color: rgba(255, 153, 0, 1);
            text-transform: uppercase;
            z-index: 1;
            position: relative;
        }

        h3 {
            font-family: Amazon, sans-serif;
            font-size: 24px;
            font-weight: 700;
            line-height: 29px;
            letter-spacing: -0.02em;
            text-align: left;
            position: relative;
            z-index: 1;
        }

        .info-btn {
            border-radius: 4rem;
            font-family: Amazon, sans-serif;
            font-size: 16px;
            font-weight: 700;
            line-height: 19px;
            letter-spacing: 0px;
            text-align: center;
            height: 3rem;
            width: 9rem;
            z-index: 1;
            margin-right: 5.5rem;
        }

        .starter-img {
            z-index: -10;
        }
    }
}