.progress-slate-blue{
    .ant-progress-bg{
        background: $slate-blue;
    }

    .ant-progress-inner{
        background: white;
    }
}

.progress-slate-blue-2{
    .ant-progress-bg{
        background: $slate-blue-05;
    }

    .ant-progress-success-bg{
        background: $slate-blue;
    }

    .ant-progress-inner{
        background: white;
    }
}