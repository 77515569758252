.h-1 {
    font-family: Amazon;
    font-weight: bold;
    font-size: 4rem;
    letter-spacing: -2%;
}

.h-2 {
    font-family: Amazon;
    font-weight: bold;
    font-size: 2.5rem;
    letter-spacing: -2%;
}

.h-3 {
    font-family: Amazon;
    font-weight: bold;
    font-size: 1.5rem;
    letter-spacing: -2%;
}

//subtitle
.subtitle {
    font-family: Amazon;
    font-weight: normal;
    font-size: 1.5rem;
}

.text {
    font-family: Amazon;
    font-weight: normal;
    font-size: 1rem;
    line-height: 140%;
}

.text-small {
    font-family: Amazon;
    font-weight: normal;
    font-size: 0.875rem;
}

.pretitle {
    font-family: Amazon;
    font-weight: bold;
    font-size: 0.75rem;
    letter-spacing: 3%;
}

.btn-text {
    font-family: Amazon;
    font-weight: bold;
    font-size: 1rem;
    letter-spacing: 3%;
}

.btn-text-sm {
    font-family: Amazon;
    font-weight: 500;
    font-size: 0.825rem;
    letter-spacing: 3%;
}

.link {
    font-family: Amazon;
    font-weight: bold;
    font-size: 0.875rem;
    text-decoration: underline;
}

.table-title{
    font-family: Amazon;
    font-weight: bold;
    font-size: 12px;
}

.table-text{
    font-family: Amazon;
    font-weight: normal;
    font-size: 12px;
}