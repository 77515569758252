.border-oneamz-yellow{
    border: 1px solid $oneamz-yellow;
}

.border-slate-blue{
    border: 1px solid $slate-blue;
}

.border-slate-blue-02{
    border: 1px solid $slate-blue-02;
}

.border-slate-blue-05{
    border: 1px solid $slate-blue-05;
}

.border-dorian{
    border: 1px solid $dorian;
}

.border-cloud{
    border: 1px solid $cloud;
}

.border-cloud-02{
    border: 1px solid $cloud-02;
}

.border-cloud-05{
    border: 1px solid $cloud-05;
}

.border-onyx{
    border: 1px solid $onyx;
}

.border-success{
    border: 1px solid $success;
}

.border-error{
    border: 1px solid $error;
}

.border-warning{
    border: 1px solid $warning;
}

.border-info{
    border: 1px solid $info;
}

.border-none{
    border: none;
}

//Positions

.border-right-slate-blue{
    border-right: 1px solid $slate-blue;
}