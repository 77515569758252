.switch-oneamz-yellow.ant-switch-checked {
    background: $oneamz-yellow !important;
}

.switch-bg {
    background: #E6E6E6 !important;
}

.switch-slate-blue.ant-switch-checked {
    background: $slate-blue !important;
}
