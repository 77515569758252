.order-detail-card{
        position: relative; // Pozisyonlamayı kontrol etmek için
        overflow: hidden; // Resim Paper sınırlarını aştığında gizlemek için

        &::after {
            // Pseudo-element ile arka plan resmi ekleme
            content: ""; // Pseudo-element için içerik
            position: absolute; // Pozisyonu absolute yaparak konumlandırma
            bottom: -3rem; // Alt kısma yasla
            right: -12rem; // Sağ kısma yasla
            background-image: url("../../V2/OrderDetailV2/amazon-fba.png"); // Resim yolu
            background-size: contain; // Resmin boyutunu sığdır
            background-repeat: no-repeat; // Tekrar etmemesi için
            width: 700px;
            height: 300px;
            z-index: 0;

            @media (max-width:1198.8px) {
                width: 300px;
                height: 100px;
            }
            
        }
}