.amz-input {
    height: 55px;
}

.amz-input-44px {
    height: 44px;
}

.amz-select-input {
    .ant-select-selector {
        background: transparent !important;
        border: none !important;
    }
}

.amz-select-input {
    .ant-select-selector {
        background: transparent !important;
        border: none !important;
    }
}