.amz-checkbox {

    span.ant-checkbox-inner {
        background: pure-white;
        border: 1px solid #BCBCBC;

        height: 20px;
        width: 20px;
    }

    span.ant-checkbox-checked {

        span.ant-checkbox-inner {
            background: $slate-blue !important;

        }
    }
}