.vertical-divider {
    height: auto;
    min-height: 100%;
    background: $slate-blue-02;
    margin: 2rem 0 0 0;
}

.slate-blue-divider {
    background: $slate-blue-02;
    margin: 0;
}